import React, { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import {
  createAuthUserWithEmailAndPassword,
  signInAuthUserWithEmailAndPassword,
  signInWithGooglePopup,
  createUserDocFromAuth,
} from "../../utils/firebase/firebase.utils";
import { useUserContext } from "../../contexts/user_context";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { VStack } from "components/ui/containers/stacks";
import {
  SPACE_BETWEEN_INPUT,
  SPACE_BETWEEN_STACK,
  SPACE_BETWEEN_TEXT,
} from "components/ui/spacings";
import { Panel } from "components/ui/cards";
import { Label, RegularText, SmallText } from "components/ui/texts";
import { H4, H5 } from "components/ui/headings";
import { ColorsSystem } from "components/ui/colors";
import styled from "styled-components";
import { TextInput } from "components/ui/inputs/text_input";
import { FilledButton } from "components/ui/buttons";

const defaultFormFields = {
  email: "",
  password: "",
};

export const SignInForm = () => {
  // const { currentUser } = useContext(UserContext);
  const { currentUser } = useUserContext();

  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address"),
    password: Yup.string().required("obligatoire"),
  });

  const handleSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    // event.preventDefault();

    try {
      await signInAuthUserWithEmailAndPassword(email, password);
      resetFormFields();
    } catch (error: any) {
      console.error("error");
      switch (error.code) {
        case "auth/wrong-password":
          alert("mot de passe incorrect pour cet email");
          break;
        case "auth/user-not-found":
          alert("email inconnu");
          break;
        default:
          console.log("error", error);
          break;
      }
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <>
      {currentUser ? (
        <Navigate to={"/profile"}></Navigate>
      ) : (
        <SignInFormWrapper>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ setFieldValue, dirty, values }) => {
              console.log(values);

              return (
                <Form>
                  <Panel>
                    <Wrapper gap={SPACE_BETWEEN_STACK}>
                      <VStack gap={SPACE_BETWEEN_TEXT}>
                        <H4 color={ColorsSystem.blue}>Bonjour !</H4>
                        <SmallText>
                          Connectez-vous pour consulter le site
                        </SmallText>
                      </VStack>
                      <VStack gap={SPACE_BETWEEN_INPUT}>
                        <Label label={"Email"} />
                        <TextInput
                          required
                          type="email"
                          name="email"
                          value={email}
                          // onChange={handleChange}
                          id={"email"}
                        />
                        <Label label={"Mot de passe"} />
                        <TextInput
                          required
                          type="password"
                          name="password"
                          value={password}
                          // onChange={handleChange}
                          id={"password"}
                        />
                      </VStack>
                      <ButtonWrapper>
                        <FilledButton
                          title=" Je me connecte"
                          buttonSize="medium"
                          type="submit"
                        />
                      </ButtonWrapper>
                    </Wrapper>
                  </Panel>
                </Form>
              );
            }}
          </Formik>
        </SignInFormWrapper>
      )}
    </>
  );
};

const Wrapper = styled(VStack)`
  padding: 10px 40px 20px 20px;
`;

const ButtonWrapper = styled(VStack)`
  padding-top: 20px;
  align-items: center;
`;

{
  /* <form onSubmit={handleSubmit}>
            <TextInput
            label="Email"
            required
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            id={""}
            />
            <TextInput
            label="Mot de passe"
            required
            type="password"
            name="password"
            value={password}
            onChange={handleChange}
            id={""}
            />
            <RegularButton type="submit">Je me connecte</RegularButton>
          </form> */
}

const SignInFormWrapper = styled(VStack)`
  align-items: center;
  margin-top: 50px;
`;
