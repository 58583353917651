import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { IoCloseCircleOutline } from "react-icons/io5";

import { useUserContext } from "../../contexts/user_context";
import { RegularText } from "../../components/ui/texts";
import { ProfileCard } from "../../components/profile/profile_card";
import { UserInfos } from "types/user.types";
import { ModalContext } from "components/ui/modal";
import { Option } from "types/global_types";
import { VStack } from "components/ui/containers/stacks";
import { UpdateUserForm } from "../../components/profile/profile_forms/update_user_forms";
import { ColorsSystem } from "components/ui/colors";
import styled from "styled-components";
import { FilledButton } from "components/ui/buttons";
import { H4 } from "components/ui/headings";
import { PageWrapper } from "components/ui/containers/page";

export const Profile = () => {
  const { currentUser, loading, setLoading } = useUserContext();

  if (loading) {
    return <RegularText>Chargement en cours, veuillez patientez.</RegularText>;
  }

  return (
    <>
      {currentUser && (
        <PageWrapper>
          <H4>Bienvenue sur votre profil</H4>
          <ProfileCard user={currentUser}></ProfileCard>
        </PageWrapper>
      )}
    </>
  );
};
