import { useContext } from "react";
import { FaCross } from "react-icons/fa";

import styled, { css } from "styled-components";
import { Children, UserInfos } from "types/user.types";
import { Label, RegularText, SmallText } from "components/ui/texts";
import { FiUser } from "react-icons/fi";
import { HStack, VStack } from "components/ui/containers/stacks";
import { ColorHex, ColorsSystem } from "components/ui/colors";
import { H4, H5 } from "components/ui/headings";
import {
  SPACE_BETWEEN_INPUT,
  SPACE_BETWEEN_TEXT,
} from "components/ui/spacings";
import { ModalContext } from "components/ui/modal";
import { Option } from "types/global_types";
import { PencilButton } from "components/ui/buttons/PencilButton";
import { UpdateUserInfosForm } from "./profile_forms/update_user_infos_form";
import { UpdateUserContactsForm } from "./profile_forms/update_user_contacts_form";
import { UpdateUserAdressesForm } from "./profile_forms/update_user_adresses_form";
import {
  capitalizeFirstLetter,
  filterNullObjects,
  getCotisationFromLegacyCotisation,
  isStringNullOrEmpty,
} from "helpers/helpers";
import { cotisationsSortedByDate } from "routes/members_list/members_list_to_admin";

type ProfileCardProps = { user: UserInfos };
export const ProfileCard = ({ user }: ProfileCardProps) => {
  const { handleModal } = useContext(ModalContext);
  const handleUpdateUserInfos = (user: Option<UserInfos>): void => {
    user && handleModal(<UpdateUserInfosForm user={user} />);
  };
  const handleUpdateUserContacts = (user: Option<UserInfos>): void => {
    user && handleModal(<UpdateUserContactsForm user={user} />);
  };
  const handleUpdateUserAdresses = (user: Option<UserInfos>): void => {
    user && handleModal(<UpdateUserAdressesForm user={user} />);
  };

  return (
    <VStack alignItems="center">
      <GridWrapper>
        <AvatarPanel>
          <BasicInfos user={user} />
        </AvatarPanel>

        <AdressPanel>
          <EditButton>
            <PencilButton
              size="small"
              onClick={() => handleUpdateUserAdresses(user)}
            />
          </EditButton>
          <BluePanelTitle>{"ADRESSES"}</BluePanelTitle>
          <HStack gap={30}>
            <CivilAdressWrapper user={user} />
            <PermanentAdressWrapper user={user} />
          </HStack>
        </AdressPanel>

        <FamilyPanel>
          <BluePanelTitle>{"FAMILLES"}</BluePanelTitle>
          <Family user={user} />
        </FamilyPanel>

        <ContactsPanel>
          <EditButton>
            <PencilButton
              size="small"
              onClick={() => handleUpdateUserContacts(user)}
            />
          </EditButton>
          <BluePanelTitle>{"CONTACTS"}</BluePanelTitle>
          <Contacts user={user} />
        </ContactsPanel>

        <InfosPanel>
          <EditButton>
            <PencilButton
              size="small"
              onClick={() => handleUpdateUserInfos(user)}
            />
          </EditButton>
          <BluePanelTitle>{"INFOS"}</BluePanelTitle>
          <Infos user={user} />
        </InfosPanel>

        <CotisationsPanel>
          <BluePanelTitle>{"COTISATIONS"}</BluePanelTitle>
          <CotisationsWrapper user={user}></CotisationsWrapper>
        </CotisationsPanel>
      </GridWrapper>
    </VStack>
  );
};

const EditButton = styled.div`
  display: flex;
  position: absolute;
  height: 30px;
  width: 30px;
  top: -7px;
  right: -7px;
  background-color: ${ColorsSystem.blue};
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
`;

const BluePanelTitle = styled(H4)`
  color: ${ColorsSystem.blue};
  padding-bottom: 20px;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  padding: 30px;
`;

const commonCardStyle = css`
  position: relative;
  overflow: visible;
`;

const ProfilPanel = styled.div`
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #00000026;
  padding: 24px;
  overflow: hidden;
`;

const AvatarPanel = styled(ProfilPanel)`
  ${commonCardStyle};
  grid-row: 1/3;
  grid-column: 1/2;
  background-color: ${ColorsSystem.powderBlueOne};
`;
const AdressPanel = styled(ProfilPanel)`
  ${commonCardStyle};
  grid-row: 1;
  grid-column: 2/5;
  background-color: ${ColorsSystem.white};
`;
const FamilyPanel = styled(ProfilPanel)`
  ${commonCardStyle};
  grid-row: 2/4;
  grid-column: 4/5;
  background-color: ${ColorsSystem.beige};
`;
const ContactsPanel = styled(ProfilPanel)`
  ${commonCardStyle};
  position: relative;
  grid-row: 2;
  grid-column: 2/4;
  background-color: ${ColorsSystem.beige};
`;
const InfosPanel = styled(ProfilPanel)`
  ${commonCardStyle};
  grid-row: 3;
  grid-column: 1/4;
  background-color: ${ColorsSystem.white};
`;
const CotisationsPanel = styled(ProfilPanel)`
  ${commonCardStyle};
  grid-row: 4;
  grid-column: 1/5;
  background-color: ${ColorsSystem.white};
`;

const BasicInfos = ({ user }: ProfileCardProps) => {
  return (
    <VStack gap={14}>
      <VStack alignItems="center" gap={SPACE_BETWEEN_TEXT}>
        <VStack alignItems="center">
          <Avatar user={user} />
        </VStack>

        <VStack alignItems="center">
          <Label color={ColorsSystem.white} label={"Naissance"} />
          <RegularText color={ColorsSystem.white}>{user.birthdate}</RegularText>
        </VStack>
      </VStack>

      {user.isDeceased && user.deceasedData && (
        <VStack alignItems="center">
          <Label color={ColorsSystem.white} label={"Décès"} />
          <RegularText color={ColorsSystem.white}>
            {user.deceasedData.date}
          </RegularText>
          <RegularText color={ColorsSystem.white}>
            {user.deceasedData.place}
          </RegularText>
        </VStack>
      )}

      <VStack alignItems="center">
        <Label color={ColorsSystem.white} label={"Promotion"} />
        <RegularText color={ColorsSystem.white}>
          {isStringNullOrEmpty(user.promotion) ? " ... " : user.promotion}
        </RegularText>
      </VStack>
      <VStack alignItems="center">
        <Label color={ColorsSystem.white} label={"Grade"} />
        <RegularText color={ColorsSystem.white}>
          {isStringNullOrEmpty(user.grade) ? " ... " : user.grade}
        </RegularText>
      </VStack>
      <VStack alignItems="center">
        <Label color={ColorsSystem.white} label={"Armée"} />
        <RegularText color={ColorsSystem.white}>
          {isStringNullOrEmpty(user.army) ? " ... " : user.army}
        </RegularText>
      </VStack>
      <VStack alignItems="center">
        <Label color={ColorsSystem.white} label={"Brigade"} />
        <RegularText color={ColorsSystem.white}>
          {isStringNullOrEmpty(user.brigade) ? " ... " : user.brigade}{" "}
        </RegularText>
      </VStack>
    </VStack>
  );
};

const PermanentAdressWrapper = ({ user }: ProfileCardProps) => {
  return (
    <VStack>
      <Label label={"Adresse permanente"} />
      <VStack>
        <RegularText>
          {user.permanentAdress.numberAdress} {user.permanentAdress.adress}
        </RegularText>
        <RegularText>
          {user.permanentAdress.zipcode} {user.permanentAdress.city}
        </RegularText>
      </VStack>
    </VStack>
  );
};

const CivilAdressWrapper = ({ user }: ProfileCardProps) => {
  return (
    <VStack>
      <Label label={"Adresse civile"} />
      <VStack>
        <RegularText>
          {user.civilAdress.numberAdress} {user.civilAdress.adress}
        </RegularText>
        <RegularText>
          {user.civilAdress.zipcode} {user.civilAdress.city}
        </RegularText>
      </VStack>
    </VStack>
  );
};

const Family = ({ user }: ProfileCardProps) => {
  //TODO: add function to translate legacy marital status to new marital status
  //TODO: add update to add children
  const isMarried = user.maritalSituation.maritalStatus === "Marié";
  const isWidow = user.maritalSituation.maritalStatus === "Veuf";

  const filterChildrenArray = filterNullObjects(user.children);

  return (
    <VStack gap={SPACE_BETWEEN_TEXT}>
      <VStack gap={10}>
        <VStack>
          <Label label={"Situation familiale"} />
          <RegularText>{user.maritalSituation.maritalStatus}</RegularText>
        </VStack>

        {user.maritalSituation && (isMarried || isWidow) && (
          <VStack>
            <Label label={"Nom de l'épouse"} />
            <RegularText>
              {user.maritalSituation.wifeName &&
              user.maritalSituation.wifeName.trim() !== ""
                ? user.maritalSituation.wifeName
                : " ... "}
            </RegularText>
          </VStack>
        )}
        <VStack>
          <Label label={"Enfants"} />
          {filterChildrenArray.length > 0 ? (
            <UserChildren children={filterChildrenArray}></UserChildren>
          ) : (
            <RegularText>{"pas d'enfant"}</RegularText>
          )}
        </VStack>
      </VStack>
    </VStack>
  );
};

const UserChildren = ({ children }: { children: Children }) => {
  return (
    <VStack>
      {children.map((child) => {
        return (
          <ChildrenWrapper key={child.name}>
            <RegularText>{capitalizeFirstLetter(child.name)}</RegularText>
            {/* {child.birthdate && (
              <HStack gap={4} alignItems="baseline">
                <SmallText>né.e le</SmallText>
                <RegularText>
                  {child.birthdate}
                </RegularText>
              </HStack>
            )} */}
          </ChildrenWrapper>
        );
      })}
    </VStack>
  );
};

const ChildrenWrapper = styled(VStack)`
  margin-bottom: ${SPACE_BETWEEN_TEXT}px;
`;

const Contacts = ({ user }: ProfileCardProps) => {
  return (
    <VStack gap={SPACE_BETWEEN_INPUT}>
      <InputWithLabel label={"email"} inputText={user.email} />
      <InputWithLabel label={"ligne fixe"} inputText={user.landLineNumber} />
      <InputWithLabel label={"portable"} inputText={user.phoneNumber} />
    </VStack>
  );
};
const Infos = ({ user }: ProfileCardProps) => {
  return (
    <div>
      <RegularText>
        {isStringNullOrEmpty(user.moreInfos) ? "pas d'infos" : user.moreInfos}
      </RegularText>
    </div>
  );
};

const Avatar = ({ user }: ProfileCardProps) => {
  return (
    <div
      style={{
        marginBottom: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          width: 100,
          height: 100,
          borderRadius: 100,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: ColorsSystem.beige,
          marginBottom: 10,
        }}
      >
        <FiUser size={70} color="white"></FiUser>
      </div>
      {user.isDeceased && (
        <div style={{ position: "absolute", top: 2, right: 2 }}>
          <FaCross size={24} />
        </div>
      )}
      <H4 color={ColorsSystem.white}>{user.lastName.toLocaleUpperCase()}</H4>
      <H4 color={ColorsSystem.white}>{user.firstName}</H4>
    </div>
  );
};

const InputWithLabel = ({
  label,
  inputText,
  color,
  alignCenter,
}: {
  label: string;
  inputText: string;
  color?: ColorHex;
  alignCenter?: boolean;
}) => {
  return (
    <VStack gap={5} alignItems={alignCenter ? "center" : "flex-start"}>
      <H5 color={color}>{label}</H5>
      <RegularText color={color}>{inputText}</RegularText>
    </VStack>
  );
};

export function getFourLastLetters(string: string) {
  return string.substring(string.length - 4);
}

const CotisationsWrapper = ({ user }: ProfileCardProps) => {
  const cotisationsUpdated = user.cotisations.map((cotisation) => {
    return getCotisationFromLegacyCotisation(cotisation);
  });

  const sortedCotisations = cotisationsSortedByDate(cotisationsUpdated);
  return (
    <HorizontalCotisationsWrapper gap={16}>
      {sortedCotisations.map((cotisation, index) => {
        return (
          <CotisWrapper key={index}>
            <Label label={getFourLastLetters(cotisation.date)} />
            <RegularText>{cotisation.date}</RegularText>
            <RegularText>
              {cotisation.value} {"€"}
            </RegularText>
            <SmallText>{cotisation.valueInString}</SmallText>
            <SmallText>
              {cotisation.paymentType
                ? `payé en ${cotisation.paymentType}`
                : "paiement non précisé"}
            </SmallText>
          </CotisWrapper>
        );
      })}
    </HorizontalCotisationsWrapper>
  );
};

const HorizontalCotisationsWrapper = styled(HStack)`
  overflow: auto;
  padding: 10px;
  margin-bottom: 30px;
  margin-top: 20px;
`;

const CotisWrapper = styled(VStack)`
  border-left: 1px solid ${ColorsSystem.lightGrey};
  padding-left: 10px;
`;
