import {
  doc,
  getDoc,
  query,
  collection,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { db } from "./firebase.utils";
import { UserInfos } from "types/user.types";

export const USERS_COLLECTION = "users";

export const updatingUserDoc = async (
  userId: string,
  additionalInformation = {}
) => {
  if (!userId) return;
  const userDocRef = doc(db, "users", userId);
  const userSnapshot = await getDoc(userDocRef);

  if (userSnapshot.exists()) {
    try {
      const userData = userSnapshot.data();
      const updatedUserData = { ...userData, ...additionalInformation };
      await setDoc(userDocRef, updatedUserData);
      return updatedUserData;
    } catch (error: any) {
      throw new Error("error when updating user" + error.message);
    }
  } else {
    throw new Error("User does not exist.");
  }
};

export class FirebaseUserGateway {
  async updateUser({ userId, data }: { userId: string; data: UserInfos }) {
    // await db.collection("users").doc(userId).update(data);
    const docRef = doc(db, USERS_COLLECTION, userId);
    const userDoc = await getDoc(docRef);
    await userDoc.data();
  }

  // async getUserById(userId) {
  //   try {
  //     const docRef = db.collection("users").doc(userId);
  //     const userDoc = await docRef.get();
  //     if (!userDoc.exists) {
  //       throw new Error(`User ${userId} does not exist in Firebase`);
  //     }
  //     const user = userDoc.data();
  //     return { ...user, id: userDoc.id };
  //   } catch (err) {
  //     console.log("error getting user", err);
  //   }
  // }
  async getUserById(userId: string) {
    try {
      const docRef = doc(db, USERS_COLLECTION, userId);
      const userDoc = await getDoc(docRef);

      if (!userDoc.exists) {
        throw new Error(`User ${userId} does not exist in Firebase`);
      }
      const user = userDoc.data();
      return { ...user, id: userDoc.id };
    } catch (err) {
      console.log("error getting user", err);
    }
  }

  async getAllUsers(): Promise<UserInfos[]> {
    const collectionRef = collection(db, USERS_COLLECTION);
    const q = query(collectionRef);
    const querySnapshot = await getDocs(q);
    const documents = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as UserInfos[];
    return documents;
  }
}
